import React from "react";
import Featured from "../../components/Featured/Featured";
import SurgicalTechniques from "../../components/SurgicalTechniques/SurgicalTechniques";
import STVideos from "../../components/STVideos/STVideos";
// import CTANew1 from "../../components/CTANew1/CTANew1";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import Products from "../../components/Products/Products";
import CTANew from "../../components/CTANew/CTANew";
// import ProductDetails from "../ProductDetails/ProductDetails";
import { Link } from "react-router-dom";

const ParkSystemSet = () => {
  return (
    <div>
      <div>
        <Featured type="Featured" />
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-y-16 gap-x-8 px-4 sm:px-6 lg:max-w-7xl  lg:px-8">
             <div className="flex items-center gap-x-6">
                    <Link
                      to="/contact"
                      className="text-sm font-semibold leading-6  text-gray-900 ring-1 hover:ring-[#C39A68] hover:bg-transparent bg-[#C39A68] ring-[#C39A68] px-6 py-3"
                    >
                      Order Now <span aria-hidden="true">→</span>
                    </Link>
                  </div>
          </div>
      </div>
      {/* <div>
        <Sidebar />
      </div> */}
      <div>
        <SurgicalTechniques />
        
      </div>
      <div>
        <STVideos />
      </div>
      {/* <div className="mb-[80px]">
        <CTANew />
      </div> */}
      {/* <div id="product-list">
        <Products />
      </div> */}
      <div>
        <CTANew />
      </div>
      {/* <ProductDetails /> */}
    </div>
  );
};

export default ParkSystemSet;
