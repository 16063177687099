import React from "react";
import PectVacHeader from "../../components/PectVacHeader/PectVacHeader";

const PecVacDevice = () => {
  return (
    <div>
      <PectVacHeader />
    </div>
  );
};

export default PecVacDevice;
